import React, { useEffect, useState } from "react";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { fetchEntireConversation } from "../../../../Shared/Utilities/api-helper/FetchMessage";
import { analyzeConversationHistory } from "../../../../Shared/Utilities/api-helper/analyze-chat-history";
import Loader from "../../../../Shared/components/loader";
import "../EmailTranslation/Home/index.css";
const EmailSummary = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const goBack = () => {
    navigate("/EmailTranslation");
  };

  const fetchData = async () => {
    setLoading(true); // Set loading to true at the start of the process
    try {
      const res = await fetchEntireConversation();
      const historyResponse = await analyzeConversationHistory(res);
      console.log(historyResponse);
    } catch (error) {
      console.error("Error fetching conversation or analyzing history:", error);
    } finally {
      setLoading(false); // Ensure loading is false after the process completes
    }
  };
  // Fetch all attachments on component mount
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      {loading && <Loader />}

      <h3
        style={{
          padding: "10px",
          marginBottom: "20px",
        }}
      >
        {" "}
        <MdOutlineKeyboardBackspace
          fontSize={25}
          onClick={goBack}
          style={{
            margin: "10px",
            cursor: "pointer",
          }}
        />
        E-Mail Zusammenfassung
      </h3>
      <div className="email">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam laborum, fugiat et odit rem maiores sint, quasi
        qui porro ab repellendus nam quas labore cupiditate a molestias. Sunt optio rerum sed iste, magnam saepe maxime
        ipsa officia quis reiciendis aspernatur veniam dolorum, soluta odit commodi! Officiis eligendi voluptates est
        excepturi.
      </div>
    </div>
  );
};

export default EmailSummary;
