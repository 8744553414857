import React, { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../..";
import "./Compose.css";
import { Frown, MessageSquare } from "lucide-react";
import Prompts from "./Prompts/Prompts";
import Notification from "./Notification/Notification";
import { getBodyAsync } from "../../../Shared/Utilities/getComposeEmailBody";
import { rewriteDraftedReply } from "../../../Shared/Utilities/api-helper/Rewrite";
import { handleInsertBodyContent } from "../../../Shared/Utilities/InsertToEmailBody";
import Footer from "../Shared/Footer/Footer";
import Input from "../Shared/Input/Input";
import WorkingBtn from "./WorkingBtn/WorkingBtn";
import { SpeechToText } from "../../../Shared/Utilities/api-helper/SpeechToText";
import VoiceRecorder from "../../../Shared/components/VoiceRecorder";
import logo from "../../assets/Addeso-logo.png"
const Compose = () => {
  const language = useContext(TranslationContext);
  const [emptyPromptNotification, setEmptyPromptNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [irrelevantPromptNotification, setIrrelevantPromptNotification] = useState(true);
  const [instruction, setInstruction] = useState("");

  useEffect(() => {
    if (irrelevantPromptNotification) {
      setTimeout(() => {
        setIrrelevantPromptNotification(false);
      }, 3000);
    }
  }, [irrelevantPromptNotification]);

  const handlePromptClick = async (text) => {
    // setInputValue(text);

    const body = await getBodyAsync();
    if (body.trim() !== "") {
      setEmptyPromptNotification(false);
      handleRewriteEmail(text);
    } else {
      setEmptyPromptNotification(true);
    }
  };
  const handleSend = async () => {
    const body = await getBodyAsync();
    if (body.trim() !== "") {
      setEmptyPromptNotification(false);
      handleRewriteEmail(instruction);
    } else {
      setEmptyPromptNotification(true);
    }
  };
  const handleRewrite = () => {
    handleRewriteEmail(instruction);
  };

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      const body = await getBodyAsync();
      if (body.trim() !== "") {
        setEmptyPromptNotification(false);
        handleRewrite();
      } else {
        setEmptyPromptNotification(true);
      }
    }
  };
  const handleRewriteEmail = async (instruction) => {
    setIsLoading(true);
    try {
      const body = await getBodyAsync();

      const res = await rewriteDraftedReply(body, instruction);
      onInsert(res.answer);

      setIsLoading(false);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onInsert = (content) => {
    handleInsertBodyContent(content);
  };
  const handleTranscription = (VoiceInput) => {
    handleSpeechToText(VoiceInput);
    setEmptyPromptNotification(false);
  };

  const handleSpeechToText = (VoiceInput) => {
    // Ensure that VoiceInput is a valid Blob or File
    console.log(VoiceInput);
    setIsLoading(true);
    // Call the SpeechToText function with the provided VoiceInput
    SpeechToText(VoiceInput)
      .then((res) => {
        // Handle successful response
        console.log("Speech-to-Text Result:", res);
        setInstruction(res.text);
        setIsLoading(false);

        handleSend();
        // You can process the response further here, such as updating the UI
      })
      .catch((error) => {
        // Handle any errors that occurred during the conversion
        setIsLoading(false);
        console.error("Error during speech-to-text conversion:", error);
      });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          paddingRight: "10px",
        }}
      >
        {/* <MenuBar /> */}
      </div>
      <div
        className="composeContainer"
        style={{
          paddingTop: irrelevantPromptNotification ? "0px" : emptyPromptNotification ? "5px" : "90px",
        }}
      >
        <img src={logo} alt="" width={100} className="image" />
        <div>

          <div className="content">
            <h1
              style={{
                color: "#C8B630",
                fontSize: "22px",
                fontWeight: "700",
                fontFamily: "Montserrat",
              }}
            >
              {language.welcome.title}
            </h1>
            <p
              style={{
                color: "#2E3A48",
                fontFamily: "Montserrat",
                fontWeight: "600",
              }}
            >
              {language.welcome.subTitle}
            </p>
            <div className="button-container">
              <>
                <Prompts
                  onPromptClick={handlePromptClick}
                  isIrrelevant={irrelevantPromptNotification}
                  isRelevant={emptyPromptNotification}
                  isLoading={isLoading}
                />
                {isLoading ? (
                  <WorkingBtn />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      <VoiceRecorder top="-40%" setIsLoading={setIsLoading} handleTranscription={handleTranscription} />
                    </div>
                    <Input
                      value={instruction}
                      onChange={(e) => setInstruction(e.target.value)}
                      onSubmit={handleSend}
                      onKeyDown={handleKeyDown}
                      placeholder={language.promptInput.InputPlaceholder}
                      isLoading={isLoading}
                      buttonStyle={{
                        // backgroundColor: "#2E3A48",
                        color: "rgba(27, 110, 195, 1)",
                        padding: "14px",
                      }}
                      containerStyle={{
                        display: "flex",
                        width: "83%",
                        height: "50px",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "8px",
                        background: "#ffffff",
                        // borderRadius: "50px",
                        border: "1px solid #80808052",
                        position: irrelevantPromptNotification ? "static" : "fixed",
                        bottom: emptyPromptNotification ? "207px" : "45px",
                      }}
                    />
                  </div>
                )}
              </>

              {
                emptyPromptNotification ? (<p>Es wird eine Eingabe benötigt</p>):(null)
              }
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Compose;
