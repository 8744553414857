import config from "../../../../config";

export async function analyzeConversationHistory(historyData) {
  return new Promise((resolve, reject) => {
    fetch(`${config.API_BASE_URL}analyze_history`, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(historyData),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      })
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
}
