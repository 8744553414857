import React, { useContext, useState } from "react";
import { TranslationContext } from "../../../..";
import { useNavigate } from "react-router-dom";
import { fetchConversationMessages } from "../../../../Shared/Utilities/api-helper/FetchMessage";
import "./Home.css";
import { Frown } from "lucide-react";
import Notification from "../../Compose/Notification/Notification";
import HomeLogo from "../../../../../../assets/Findus-logo.png";
import MenuBar from "../../Shared/MenuBar/MenuBar";
import Footer from "../../Shared/Footer/Footer";
import Button from "../../Shared/Button/Button";
import { marked } from "marked";
import SummarizeEmail from "../../Shared/ActionBtns/email-summary";
import TranslateEmail from "../../Shared/ActionBtns/translate-email";
import SummarizeAttachment from "../../Shared/ActionBtns/attachment-summary";
import Length from "../../Shared/Dropdowns/length";
import { replyEmail } from "../../../../Shared/Utilities/api-helper/gptService";
import Stylize from "../../Shared/Dropdowns/stylize";
import WorkingBtn from "../../Compose/WorkingBtn/WorkingBtn";
import { writeMail } from "../../../../Shared/Utilities/api-helper/write-mail";
import Prompts from "../../Compose/Prompts/Prompts";
import { handleInsertBodyContent } from "../../../../Shared/Utilities/InsertToEmailBody";
import { getBodyAsync } from "../../../../Shared/Utilities/getComposeEmailBody";
import { improveGrammar } from "../../../../Shared/Utilities/api-helper/improve-grammar";
const Home = () => {
  const language = useContext(TranslationContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [writingLength, setWritingLength] = useState("short");
  const [writingStyle, setWritingStyle] = useState("formal");
  const [emptyPromptNotification, setEmptyPromptNotification] = useState(false);

  const handleGenerateDraft = () => {
    fetchData();
  };
  const onInsert = (content) => {
    handleInsertBodyContent(content);
  };
  const handlePromptClick = async (text) => {
    setIsLoading(true);

    try {
      const body = await getBodyAsync();

      if (body.trim() === "") {
        setEmptyPromptNotification(true);
        return;
      }

      setEmptyPromptNotification(false);

      if (text.includes("Spell")) {
        const improvedGrammar = await improveGrammar(body);
        onInsert(marked(improvedGrammar.answer[0]));
      } else {
        handleRewriteEmail(text);
      }
    } catch (error) {
      console.error("Error handling prompt click:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleRewriteEmail = async (instruction) => {
    setIsLoading(true);
    try {
      const body = await getBodyAsync();

      //const res = await rewriteDraftedReply(body, instruction, wrtingStyle, wrtingLength);
      const res = await writeMail(body, instruction, writingStyle, writingLength);

      onInsert(marked(res.answer[0]));

      setIsLoading(false);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const message = await fetchConversationMessages();

      // const reply = await replyEmail(message.plainTextContent);
      const reply = await writeMail(message.content, "", writingStyle, writingLength);

      let parsedContent = reply.answer[0];

      navigate("/GeneratedDraft", { state: { replyContent: parsedContent } });
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <WorkingBtn />}
      {/* <div
        style={{
          display: "flex",
          justifyContent: "end",
          paddingRight: "10px",
        }}
      >
        <MenuBar />
      </div> */}
      <div className="GeneratedDraftContainer">
        <img src={HomeLogo} alt="" width={100} className="image" />

        <div>
          <div className="background-overlay"></div>

          <div className="GeneratedDraftContent">
            <h1 className="heading">{language.welcome.title}</h1>
            <p className="subheading">{language.welcome.subTitle}</p>
            <div className="read-style">
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TranslateEmail setIsLoading={setIsLoading} setEmptyPromptNotification={setEmptyPromptNotification} />
                <SummarizeEmail setIsLoading={setIsLoading} setEmptyPromptNotification={setEmptyPromptNotification} />
                <SummarizeAttachment
                  setIsLoading={setIsLoading}
                  setEmptyPromptNotification={setEmptyPromptNotification}
                />
              </div>
            </div>
            {window.localStorage.getItem("emailType") === "reply" && (
              <>
                {!emptyPromptNotification ? (
                  <>
                    <div
                      style={{
                        position: "fixed",
                        bottom: "93px",
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                    >
                      <div>
                        <Prompts onPromptClick={handlePromptClick} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: "110%",
                        }}
                      >
                        <Length setWritingLength={setWritingLength} />
                        <Stylize setWritingStyle={setWritingStyle} />
                      </div>
                    </div>
                    <div className="button-container">
                      <Button
                        isLoading={isLoading}
                        onClick={fetchData}
                        loadingText={language.GeneratedDraft.WorkingButton}
                        buttonText={language.welcome.generateButton}
                      />
                    </div>
                  </>
                ) : (
                  <Notification
                    text={language.Notification.emptyPromptNotification}
                    Icon={Frown}
                    onClose={() => {
                      setEmptyPromptNotification(false);
                    }}
                  />
                )}
              </>
            )}

            {window.localStorage.getItem("emailType") === "read" && isLoading && (
              <div className="button-container">
                <Button
                  isLoading={isLoading}
                  loadingText={language.GeneratedDraft.WorkingButton}
                  buttonText={language.welcome.generateButton}
                />
              </div>
            )}

            <div
              style={{
                position: "fixed",
                bottom: 0,
                left: "50%",
                transform: "translateX(-50%)",
                width: "100%",
              }}
            >
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
