import { htmlToText } from "html-to-text";
export async function fetchConversationMessages() {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, async function (result) {
      if (result.status === "succeeded") {
        const accessToken = result.value;
        let conversationId = encodeURIComponent(Office.context.mailbox.item.conversationId);

        try {
          const messagesResponse = await fetch(
            `https://outlook.office.com/api/v2.0/me/messages?$filter=conversationId eq '${conversationId}'`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (!messagesResponse.ok) {
            throw new Error(`Error fetching messages: ${messagesResponse.statusText}`);
          }

          const data = await messagesResponse.json();
          data.value.forEach((item) => {
            console.log(encodeURIComponent(item.internetMessageId));
          });

          const parentMessage = data.value[0].Body.Content;

          if (!parentMessage) {
            throw new Error("Parent message not found.");
          }

          const plainTextContent = htmlToText(parentMessage, {
            wordwrap: 130,
          });

          resolve({
            content: plainTextContent,
            parentMessage,
            id: data.value[0].Id,
            hasAttachments: data.value[0].HasAttachments,
          });
        } catch (error) {
          reject(`Error: ${error.message}`);
        }
      } else {
        reject("Failed to get callback token");
      }
    });
  });
}

export async function fetchEntireConversation() {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, async function (result) {
      if (result.status === "succeeded") {
        const accessToken = result.value;
        let conversationId = encodeURIComponent(Office.context.mailbox.item.conversationId);

        try {
          const messagesResponse = await fetch(
            `https://outlook.office.com/api/v2.0/me/messages?$filter=conversationId eq '${conversationId}'`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (!messagesResponse.ok) {
            throw new Error(`Error fetching messages: ${messagesResponse.statusText}`);
          }

          const data = await messagesResponse.json();

          const conversationMessages=[]
          for(const item of data.value) {
            try {
              const content=item.Body.Content;
              const sender=item.From.EmailAddress.Address;
              const date=item.ReceivedDateTime;

              conversationMessages.push({
                content: content,
                sender: sender,
                date: date,
              })
            } catch(error) {
              console.log(error);
            }
          }

          console.log(conversationMessages)

          resolve(conversationMessages);
        } catch (error) {
          reject(`Error: ${error.message}`);
        }
      } else {
        reject("Failed to get callback token");
      }
    });
  });
}
