export const fetchAttachmentsFromHistory = (messageId) => {
  const attachmentUrl = `https://outlook.office.com/api/v2.0/me/messages/${messageId}/attachments`;

  return new Promise(async (resolve, reject) => {
    Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, async function (result) {
      if (result.status === "succeeded") {
        const accessToken = result.value;
        try {
          const attachmentResponse = await fetch(attachmentUrl, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },
          });

          if (!attachmentResponse.ok) {
            return reject(`Failed to fetch attachments for message ID ${messageId}: ${attachmentResponse.statusText}`);
          }

          const attachmentData = await attachmentResponse.json();

          // Filter the attachments (for example, to include only PDFs)
          const filteredAttachments = attachmentData.value;

          if (filteredAttachments.length > 0) {
            return resolve(filteredAttachments);
          } else {
            return reject("No attachments of the required type found.");
          }
        } catch (error) {
          return reject(`Error fetching attachments: ${error.message}`);
        }
      }
    });
  });
};
