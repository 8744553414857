import React from "react";
import { PiPaperclipLight } from "react-icons/pi";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { getComposeAttachment } from "../../../../Shared/Utilities/api-helper/CurrentEmail";

import "./actions.css";

const SummarizeAttachment = ({ setIsLoading, setEmptyPromptNotification }) => {
  const navigate = useNavigate();
  const gotoAttachmentSummary = async () => {
    setIsLoading(true);
    try {
      if (window.localStorage.getItem("emailType") === "read" || window.localStorage.getItem("emailType") === "reply") {
        setIsLoading(false); // Ensure loading state is reset
        navigate("/AttachmentSummary");
      } else {
        const res = await getComposeAttachment();
        setIsLoading(false);

        navigate("/AttachmentSummary");
      }
    } catch (error) {
      console.error("Error fetching attachments:", error);
      setIsLoading(false);
      // setEmptyPromptNotification(true);
    }
  };

  return (
    <div>
      <Tooltip
        title="Zusammenfassung der Anhänge"
        arrow
        placement="top"
        className="tooltip-style"
        onClick={gotoAttachmentSummary}
      >
        <div className="action-btn">
          <PiPaperclipLight fontSize={25} strokeWidth={1.2} />
        </div>
      </Tooltip>
    </div>
  );
};

export default SummarizeAttachment;
