import React, { useState, useRef } from "react";
import { Mic, CircleStop } from "lucide-react";
import "./style.css";

const VoiceRecorder = ({ handleTranscription }) => {
  const [loading, setLoading] = useState(false);
  const mediaStream = useRef(null);
  const mediaRecorder = useRef(null);
  const audioContext = useRef(null);
  const chunks = useRef([]);
  const [isRecording, setIsRecording] = useState(false);

  const handleToggleRecording = () => {
    setIsRecording(!isRecording);

    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
    setIsRecording(!isRecording);
  };

  const startRecording = async () => {
    try {
      setLoading(true);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStream.current = stream;

      // Create a new AudioContext
      audioContext.current = new AudioContext();

      // Setup the media recorder
      mediaRecorder.current = new MediaRecorder(stream);
      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.current.push(e.data);
        }
      };

      mediaRecorder.current.onstop = () => {
        const recordedBlob = new Blob(chunks.current, { type: "audio/mp3" });
        handleTranscription(recordedBlob);
        chunks.current = [];
      };

      mediaRecorder.current.start();
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = () => {
    setLoading(false);

    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.stop();
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => {
        track.stop();
      });
    }
    if (audioContext.current) {
      audioContext.current.close();
    }
  };

  return (
    <div className="p-20">
      {loading && (
        <div className="loading-container">
          <div className="pulse">
            <Mic size={50} />
          </div>
        </div>
      )}
      <div onClick={handleToggleRecording}>
        {!isRecording ? <Mic className="mic-button" /> : <CircleStop className="mic-button" />}
      </div>
    </div>
  );
};

export default VoiceRecorder;
