import React, { useState, useContext } from "react";
import "./GeneratedDraft.css";
import { TranslationContext } from "../../../..";
import { useLocation } from "react-router-dom";
import { handleInsertBodyContent } from "../../../../Shared/Utilities/InsertToEmailBody";
import MenuBar from "../../Shared/MenuBar/MenuBar";
import Footer from "../../Shared/Footer/Footer";
import Input from "../../Shared/Input/Input";
import Button from "../../Shared/Button/Button";
import VoiceRecorder from "../../../../Shared/components/VoiceRecorder";
import { SpeechToText } from "../../../../Shared/Utilities/api-helper/SpeechToText";
import { marked } from "marked";
import Stylize from "../../Shared/Dropdowns/stylize";
import Length from "../../Shared/Dropdowns/length";
import { writeMail } from "../../../../Shared/Utilities/api-helper/write-mail";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { copyToClipboard } from "../../../../Shared/Utilities/copy-content";

const GeneratedDraft = () => {
  const language = useContext(TranslationContext);
  const location = useLocation();
  const [replyContent, setReplyContent] = useState(marked(location.state?.replyContent));
   const [umarkedReplyContent, setUmarkedReplyContent] = useState(location.state?.replyContent);
  const [isLoading, setIsLoading] = useState(false);
  const [instruction, setInstruction] = useState("");
  const [writingLength, setWritingLength] = useState("short");
  const [writingStyle, setWritingStyle] = useState("casual");
  const [emptyNotice, setEmptyNotice]=useState(false)
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  const handleRewrite = () => {
    if(!instruction || instruction.length<=0) {
      setEmptyNotice(true);
      return;
    }
    handleRewriteEmail(instruction);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleRewrite();
    }
  };
  const handleRewriteEmail = async (instruction) => {
    setIsLoading(true);
    try {
      //  const res = await rewriteDraftedReply(replyContent, instruction);
      const res = await writeMail(umarkedReplyContent, instruction, writingStyle, writingLength);

      // let parsedContent = marked.parse(res.answer).toString();

      console.log(res.answer[0])

      let replResponse = marked(res.answer[0]);
      setUmarkedReplyContent(res.answer[0]);

      setReplyContent(replResponse);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onInsertReply = () => {

    copyToClipboard(umarkedReplyContent);
  };
  // Function to handle the transcription from VoiceRecorder
  const handleTranscription = (VoiceInput) => {
    handleSpeechToText(VoiceInput);
    setEmptyNotice(false);
  };

  const handleSpeechToText = (VoiceInput) => {
    console.log(VoiceInput);
    setIsLoading(true);
    SpeechToText(VoiceInput)
      .then((res) => {
        console.log("Speech-to-Text Result:", res);
        setInstruction(res.text);
        handleRewriteEmail(res.text);
      })
      .catch((error) => {
        // Handle any errors that occurred during the conversion
        setIsLoading(false);
        console.error("Error during speech-to-text conversion:", error);
      });
  };
  return (
    <div>
      <div
        style={{
          overflowY: "auto",
          padding: "24px",
          fontSize: "13px",
          height: "calc(100vh - 230px)",
        }}
      >
        <p
          style={{
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          <MdOutlineKeyboardBackspace
            fontSize={20}
            onClick={goBack}
            style={{
              margin: "10px",
              cursor: "pointer",
            }}
          />{" "}
          {language.GeneratedDraft.Response}
          {/* <MenuBar /> */}
        </p>

        <p dangerouslySetInnerHTML={{ __html: replyContent }} />
      </div>
      <hr />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Length setWritingLength={setWritingLength} />
        <Stylize setWritingStyle={setWritingStyle} />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <VoiceRecorder setIsLoading={setIsLoading} handleTranscription={handleTranscription} />
        <Input
          value={instruction}
          onChange={(e) => {
            setInstruction(e.target.value)
            if(e.target.value && e.target.value.length>0) {
              setEmptyNotice(false);
            }
          }}
          onSubmit={handleRewrite}
          onKeyDown={handleKeyDown}
          placeholder={language.GeneratedDraft.Placeholder}
          isLoading={isLoading}
          buttonStyle={{
            backgroundColor: "rgba(193, 10, 38, 1)",
            color: "white",
            padding: "14px",
          }}
          containerStyle={{
            display: "flex",
            width: "83%",
            height: "50px",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "8px",
            background: "#ffffff",
            borderRadius: "50px",
            border: "1px solid #80808052",
            margin: "auto",
          }}
        />
      </div>
      <p style={{color: "red", display: emptyNotice ? "block":"none", textAlign: "center"}}>Es wird eine Eingabe benötigt</p>
      <Button
        isLoading={isLoading}
        onClick={onInsertReply}
        loadingText={language.GeneratedDraft.WorkingButton}
        // buttonText={language.GeneratedDraft.InsertButton}
        buttonText="Kopieren"
      />
      <p
        style={{
          position: "fixed",
          bottom: "-20px",
          fontSize: "11px",
          right: "50px",
        }}
      >
        <Footer />
      </p>
    </div>
  );
};

export default GeneratedDraft;
