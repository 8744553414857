import React from "react";
import { HiOutlineMailOpen } from "react-icons/hi";
import "./actions.css";
import { useNavigate } from "react-router-dom";
import { fetchEntireConversation } from "../../../../Shared/Utilities/api-helper/FetchMessage";
import { analyzeConversationHistory } from "../../../../Shared/Utilities/api-helper/analyze-chat-history";
import Tooltip from "@mui/material/Tooltip";
import { getBodyAsync, getComposeFrom } from "../../../../Shared/Utilities/getComposeEmailBody";

const SummarizeEmail = ({ setIsLoading, setEmptyPromptNotification }) => {
  const navigate = useNavigate();
  const handleEmailEmailSummary = async () => {
    setIsLoading(true);

    try {
      let historyResponse;
      if (window.localStorage.getItem("emailType") === "compose") {
        const body = await getBodyAsync();
        if (body.trim() === "") {
          setEmptyPromptNotification(true);
          return;
        } else {
          const composeFrom = await getComposeFrom();
          const conversationMessages = [
            {
              content: body.trim(),
              sender: composeFrom.emailAddress,
              date: new Date(),
            },
          ];
          historyResponse = await analyzeConversationHistory(conversationMessages);
        }
      } else {
        const res = await fetchEntireConversation();

        historyResponse = await analyzeConversationHistory(res);
      }
      console.log(historyResponse);

      navigate("EmailSummary", { state: { summaryHistory: historyResponse } });
    } catch (error) {
      console.error("Error fetching conversation or analyzing history:", error);
    } finally {
      setIsLoading(false); // Ensure loading is false after the process completes
    }
  };
  return (
    <div>
      <Tooltip title="E-Mail Zusammenfassung" arrow placement="top" className="tooltip-style" onClick={handleEmailEmailSummary}>
        <div className="action-btn">
          <HiOutlineMailOpen fontSize={25} strokeWidth={1.2} />
        </div>
      </Tooltip>
    </div>
  );
};

export default SummarizeEmail;
