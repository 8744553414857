import React, { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../../..";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import { CalendarDays, FileText, Users, Settings, EllipsisVertical, X } from "lucide-react";
import { IconButton, TextField, Button, Box, Typography } from "@mui/material";

const MenuBar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const language = useContext(TranslationContext);
  const menuItems = [
    { text: language.MenuBar.Restart, icon: <CalendarDays fontSize="small" /> },
    { text: language.MenuBar.Report, icon: <FileText fontSize="small" /> },
    { text: language.MenuBar.Refresh, icon: <Users fontSize="small" /> },
    { text: language.MenuBar.ManageAddin, icon: <Settings fontSize="small" /> },
  ];

  const [signature, setSignature] = useState('');

  useEffect(() => {
    const savedSignature = localStorage.getItem('userSignature');
    if (savedSignature) {
      setSignature(savedSignature);
    }
  }, []);

  const saveSignature = () => {
    localStorage.setItem('userSignature', signature.trim());
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (text) => {
    console.log("Clicked Menu Item:", text);
    setAnchorEl(null);
  };

  const handleInputChange = (e) => {
    setSignature(e.target.value);
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{
          backgroundColor: open ? "rgba(0, 231, 254, 1)" : "#F7F7F7",
          color: open ? "white" : "#2E3A48",
        }}
      >
        {open ? <X /> : <EllipsisVertical />}
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box sx={{ maxWidth: 600, margin: 'auto', padding: 2 }}>
              <Typography variant="h6" gutterBottom>
                Email Signature
              </Typography>
              <TextField
                multiline
                rows={6}
                fullWidth
                variant="outlined"
                value={signature}
                onChange={handleInputChange}
                placeholder="Paste your HTML signature here"
                sx={{ marginBottom: 2 }}
              />
              <Button variant="contained" onClick={saveSignature}>
                Save Signature
              </Button>
              {signature && (
                <Box sx={{ marginTop: 2, border: '1px solid #ccc', padding: 2 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Preview:
                  </Typography>
                  <div dangerouslySetInnerHTML={{ __html: signature }} />
                </Box>
              )}
          </Box>
      </Menu>
    </div>
  );
};

export default MenuBar;
