import React, { useContext } from "react";
import { TranslationContext } from "../../../..";
const WorkingBtn = () => {
  const language = useContext(TranslationContext);

  return (
    <div>
      <button
        shape="circular"
        style={{
          position: "fixed",
          borderRadius: "50px",
          bottom: "45px",
          width: "90%",
          height: "48px",
          backgroundColor: "#e2e3f7",
          color: "#2E3A48",
          right: "14px",
          border: "1px solid lightgray",
          zIndex: "22222",
        }}
        disabled="true"
      >
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 35 35"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-2"
            style={{
              rotate: "180deg",
              marginBottom: "8px",
              marginRight: "6px",
            }}
            width={30}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
            />
          </svg>{" "}
          {language.GeneratedDraft.WorkingButton}
        </>
      </button>
    </div>
  );
};

export default WorkingBtn;
