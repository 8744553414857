import React from "react";
import { PiPaperclipLight } from "react-icons/pi";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";

import "./actions.css";
const SummarizeAttachment = () => {
  const navigate = useNavigate();
  const gotoAttachmentSummary = () => {
    navigate("AttachmentSummary");
  };
  return (
    <div>
      <Tooltip
        title="Attachment Summary"
        arrow
        placement="top"
        className="tooltip-style"
        onClick={gotoAttachmentSummary}
      >
        <div className="action-btn">
          <PiPaperclipLight fontSize={25} strokeWidth={1.2} />
        </div>
      </Tooltip>
    </div>
  );
};

export default SummarizeAttachment;
