import { analyzeAttachment } from "../attachment-summary";
import { fetchConversationMessages } from "../FetchMessage";
import { fetchAttachmentsFromHistory } from "../fetchItemAttachments";

export function getEmailBody() {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.body.getAsync(
      "text", // Can also be "html" for HTML content
      (asyncResult) => {
        if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
          resolve(asyncResult.value); // Resolve with the email body
        } else {
          reject(asyncResult.error); // Reject with the error
        }
      }
    );
  });
}
export function getAllAttachments() {
  return new Promise(async (resolve, reject) => {
    let attachments = [];

    // Check if the email type is "reply"
    if (window.localStorage.getItem("emailType") === "reply") {
      try {
        const message = await fetchConversationMessages();

        // If the message has attachments, fetch them
        if (message.hasAttachments) {
          attachments = await fetchAttachmentsFromHistory(message.id);
          attachments = attachments.map((attachment) => ({
            id: attachment.Id,
            contentType: attachment.ContentType,
            name: attachment.Name,
          }));
        }
      } catch (error) {
        reject(error); // Reject in case of an error in fetching the conversation messages
        return;
      }
    } else {
      // For other types of emails, get attachments from the current item
      const itemAttachments = Office.context.mailbox.item.attachments;

      // Filter for PDF attachments
      if (itemAttachments && itemAttachments.length > 0) {
        attachments = itemAttachments;
      }
    }

    // If attachments are found, resolve them
    if (attachments.length > 0) {
      resolve(attachments.filter((item) => item.contentType.includes("pdf")));
    } else {
      resolve([]);
    }
  });
}

export function getAttachmentContent(attachment) {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.getAttachmentContentAsync(attachment.id, (asyncResult) => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        console.log(asyncResult.value);
        let file = dataURLtoFile(
          "data:" + attachment.contentType + ";base64," + asyncResult.value.content,
          attachment.name
        );
        analyzeAttachment(file).then((res) => {
          resolve(res); // Returns the attachment content
        });
      } else {
        reject(asyncResult.error); // Returns the error
      }
    });
  });
}

//function to convert base64 to file for uploading
function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}
