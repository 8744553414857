import config from "../../../../config";
export async function fetchLanguages() {
  return new Promise((resolve, reject) => {
    fetch(`${config.API_BASE_URL}lanugages`, {
      method: "GET",
      headers: {
        accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      })
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
}
