import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TbFileTypeDocx } from "react-icons/tb";
import { getAllAttachments, getAttachmentContent } from "../../../../Shared/Utilities/api-helper/CurrentEmail";
import Loader from "../../../../Shared/components/loader";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { HiOutlineMailOpen } from "react-icons/hi";
import WorkingBtn from "../../Compose/WorkingBtn/WorkingBtn";
import { useNavigate } from "react-router-dom";
import "../EmailTranslation/Home/index.css";
import { getComposeAttachment } from "../../../../Shared/Utilities/api-helper/CurrentEmail";

const AttachmentSummary = () => {
  const [attachments, setAttachments] = useState([]);
  const [attachmentsContent, setAttachmentsContent] = useState({});
  const [loader, setLoader] = useState(true);
  const [expanded, setExpanded] = React.useState(null);

  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  useEffect(() => {
    const fetchAttachments = async () => {
      setLoader(true);

      try {
        const emailType = window.localStorage.getItem("emailType");

        if (emailType === "compose") {
          const composeAttachments = await getComposeAttachment();
          console.log(composeAttachments);
          setAttachments(composeAttachments);
        } else if (emailType === "reply") {
          const composeAttachments = await getComposeAttachment();
          const allAttachments = await getAllAttachments();

          console.log(composeAttachments, allAttachments);

          // Combine the attachments
          const combinedAttachments = [...composeAttachments, ...allAttachments];
          setAttachments(combinedAttachments);
        } else {
          const allAttachments = await getAllAttachments();
          console.log(allAttachments);
          setAttachments(allAttachments);
        }
      } catch (error) {
        console.error("Error fetching attachments:", error);
      } finally {
        setLoader(false); // Ensure loader stops after all calls finish
      }
    };

    fetchAttachments();
  }, []);

  // Handle accordion state and API call for fetching content
  const handleAccordionChange = (attachment, panel) => async (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);

    if (isExpanded && !attachmentsContent[attachment.name]) {
      // Fetch content only if the accordion is expanded and content is not already loaded
      setLoader(true);
      try {
        const res = await getAttachmentContent(attachment);
        console.log(res);
        setAttachmentsContent((prev) => ({
          ...prev,
          [attachment.name]: res.answer[0], // Store content by attachment name
        }));
      } catch (error) {
        console.error("Error fetching attachment content:", error);
      } finally {
        setLoader(false); // Ensure loader is stopped even if an error occurs
      }
    }
  };

  return (
    <div>
      {loader && <WorkingBtn />}

      <h3 style={{ padding: "15px" }}>
        <MdOutlineKeyboardBackspace
          fontSize={25}
          onClick={goBack}
          style={{
            margin: "10px",
            cursor: "pointer",
          }}
        />
        Zusammenfassung der Anhänge
      </h3>
      <div>
        {attachments.length > 0
          ? attachments.map((attachment, index) => (
              <Accordion
                sx={{
                  border: "2px solid #F2F2F2",
                  borderLeft: expanded === index ? "4px solid #000" : "none", // Conditional styling
                  boxShadow: "none",
                  maxHeight: "50vh",
                  overflow: "auto",
                }}
                key={index}
                expanded={expanded === index} // Track if it's open
                onChange={!loader && handleAccordionChange(attachment, index)} // Pass index and attachment
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <HiOutlineMailOpen fontSize={24} style={{ paddingRight: "7px" }} />
                  {attachment.name}
                </AccordionSummary>
                <AccordionDetails>
                  {attachmentsContent[attachment.name] || "Warten Sie einen Moment, um die Zusammenfassung des Anhangs zu laden."}
                </AccordionDetails>
              </Accordion>
            ))
          : !loader && <p style={{ textAlign: "center" }}>Keine PDF-Anhänge gefunden</p>}
      </div>
    </div>
  );
};

export default AttachmentSummary;
