import config from "../../../../config";

export async function translateMail(targetLanguage, content) {
  return new Promise((resolve, reject) => {
    fetch(`${config.API_BASE_URL}translate_mail`, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        target_language: targetLanguage,
        content: content,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      })
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
}
