import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TbFileTypeDocx } from "react-icons/tb";
import { getAllAttachments, getAttachmentContent } from "../../../../Shared/Utilities/api-helper/CurrentEmail";
import Loader from "../../../../Shared/components/loader";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { HiOutlineMailOpen } from "react-icons/hi";
import WorkingBtn from "../../Compose/WorkingBtn/WorkingBtn";
import { useNavigate } from "react-router-dom";
import "../EmailTranslation/Home/index.css";

const AttachmentSummary = () => {
  const [attachments, setAttachments] = useState([]);
  const [attachmentsContent, setAttachmentsContent] = useState({});
  const [loader, setLoader] = useState(false);
  const [expanded, setExpanded] = React.useState(null);

  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  // Fetch all attachments on component mount
  useEffect(() => {
    setLoader(true);
    getAllAttachments().then((res) => {
      setLoader(false);
      console.log(res);
      setAttachments(res);
    });
  }, []);

  // Handle accordion state and API call for fetching content
  const handleAccordionChange = (attachment, panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);

    if (isExpanded && !attachmentsContent[attachment.name]) {
      // Fetch content only if the accordion is expanded and content is not already loaded
      setLoader(true);
      getAttachmentContent(attachment).then((res) => {
        console.log(res);
        setAttachmentsContent((prev) => ({
          ...prev,
          [attachment.name]: res.answer[0], // Store content by attachment name
        }));
        setLoader(false);
      });
    }
  };

  return (
    <div>
      {loader && <WorkingBtn />}

      <h3 style={{ padding: "15px" }}>
        <MdOutlineKeyboardBackspace
          fontSize={25}
          onClick={goBack}
          style={{
            margin: "10px",
            cursor: "pointer",
          }}
        />
        Attachment Summary
      </h3>
      <div>
        {attachments.length > 0
          ? attachments.map((attachment, index) => (
              <Accordion
                sx={{
                  border: "2px solid #F2F2F2",
                  borderLeft: expanded === index ? "4px solid #000" : "none", // Conditional styling
                  boxShadow: "none",
                }}
                key={index}
                expanded={expanded === index} // Track if it's open
                onChange={!loader && handleAccordionChange(attachment, index)} // Pass index and attachment
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <HiOutlineMailOpen fontSize={24} style={{ paddingRight: "7px" }} />
                  {attachment.name}
                </AccordionSummary>
                <AccordionDetails className="accordion-details">
                  {attachmentsContent[attachment.name] || "Wait a moment to load the attachment summary"}
                </AccordionDetails>
              </Accordion>
            ))
          : !loader && <p style={{ textAlign: "center" }}>No PDF attachmebnts found</p>}
      </div>
    </div>
  );
};

export default AttachmentSummary;
