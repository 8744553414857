import React, { useEffect, useContext } from "react";
import { TranslationContext } from "../../../..";

import { getWritingLength } from "../../../../Shared/Utilities/api-helper/writting-length";

const Length = ({ setWritingLength }) => {
  const [writingLengths, setWritingLengths] = React.useState({});
  const language = useContext(TranslationContext);

  const de_lang=new Map();
  de_lang.set("short", "Kurz")
  de_lang.set("medium", "Medium")
  de_lang.set("long", "Lang")

  useEffect(() => {
    const fetchWritingLengths = async () => {
      try {
        const lengthResponse = await getWritingLength(); // Call your API function
        setWritingLengths(lengthResponse); // Update state with the response
        setWritingLength(Object.values(lengthResponse)[0]);
      } catch (error) {
        console.error("Failed to fetch writing lengths:", error);
      }
    };

    fetchWritingLengths();
  }, []);

  const handleLengthChange = (e) => {
    e.preventDefault();
    setWritingLength(e.target.value);
  };


  return (
    <div>
      <button
        shape="circular"
        style={{
          borderRadius: "50px",
          fontSize: "12px",
          padding: "8px",
          backgroundColor: "rgb(251 228 217 / 51%)",
          color: "#2E3A48",
          border: "1px solid lightgray",
          margin: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <b>{language.dropdowns.Length}</b>{" "}
        <select
          name="Length"
          id="Length"
          style={{
            outline: "none",
            border: "none",
            backgroundColor: "rgb(251 228 217 / 11%)",
          }}
          onChange={handleLengthChange}
        >
          {Object.keys(writingLengths).length === 0 ? (
            <option>Lädt...</option> // Default option while waiting for data
          ) : (
            Object.keys(writingLengths).map((key) => (
              <option key={key} value={key}>
                {de_lang.get(key)}
              </option>
            ))
          )}
        </select>
      </button>
    </div>
  );
};

export default Length;
