export const getWritingStyles = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}writing_styles`, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        // Reject promise if the HTTP status indicates an error
        reject(`HTTP error! status: ${response.status}`);
      }

      // Parse and resolve the JSON response
      const data = await response.json();
      resolve(data);
    } catch (error) {
      // Reject the promise with the error message
      reject(`Fetch failed: ${error.message}`);
    }
  });
};
