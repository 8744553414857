import React, { useEffect, useContext } from "react";
import { getWritingStyles } from "../../../../Shared/Utilities/api-helper/writting-styles";
import { TranslationContext } from "../../../..";

const Stylize = ({ setWritingStyle }) => {
  const language = useContext(TranslationContext);

  const [writingStyles, setWritingStyles] = React.useState({});

  const de_lang=new Map();
  de_lang.set("casual", "Leger")
  de_lang.set("formal", "Formal")
  de_lang.set("direct", "Direkt")
  de_lang.set("poem", "Lyrisch")

  useEffect(() => {
    const fetchWritingStyles = async () => {
      try {
        const lengthResponse = await getWritingStyles(); // Call your API function
        setWritingStyles(lengthResponse); // Update state with the response
        setWritingStyle(Object.values(lengthResponse)[0]);
      } catch (error) {
        console.error("Failed to fetch writing lengths:", error);
      }
    };

    fetchWritingStyles();
  }, []);

  const handleStyleChange = (e) => {
    setWritingStyle(e.target.value);
  };

  return (
    <div>
      <button
        shape="circular"
        style={{
          borderRadius: "50px",
          fontSize: "12px",
          padding: "8px",
          backgroundColor: "rgb(251 228 217 / 51%)",
          color: "#2E3A48",
          border: "1px solid lightgray",
          margin: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <b>{language.dropdowns.Stylize}:</b>{" "}
        <select
          name="Stylize"
          id="Stylize"
          style={{
            outline: "none",
            border: "none",
            backgroundColor: "rgb(251 228 217 / 11%)",
          }}
          onChange={handleStyleChange}
        >
          {Object.keys(writingStyles).length === 0 ? (
            <option>Lädt...</option> // Default option while waiting for data
          ) : (
            Object.keys(writingStyles).map((key) => (
              <option key={key} value={key}>
                {de_lang.get(key)}
              </option>
            ))
          )}
        </select>
      </button>
    </div>
  );
};

export default Stylize;
