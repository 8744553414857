import React, { useEffect } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { IoIosArrowDown } from "react-icons/io";
import { getWritingLength } from "../../../../Shared/Utilities/api-helper/writting-length";

const Length = ({ setWritingLength }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [writingLengths, setWritingLengths] = React.useState({});

  useEffect(() => {
    const fetchWritingLengths = async () => {
      try {
        const lengthResponse = await getWritingLength(); // Call your API function
        setWritingLengths(lengthResponse); // Update state with the response
      } catch (error) {
        console.error("Failed to fetch writing lengths:", error);
      }
    };

    fetchWritingLengths();
  }, []);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLengthChange = (e) => {
    e.preventDefault();
    setWritingLength(e.target.value);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <button
        // key={index}
        shape="circular"
        // onClick={() => onPromptClick(prompt.text)}
        style={{
          borderRadius: "50px",
          fontSize: "12px",
          padding: "8px",
          backgroundColor: "#dfeef7",
          color: "#2E3A48",
          border: "1px solid lightgray",
          margin: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <b>Length:</b>{" "}
        <select
          name="Length"
          id="Length"
          style={{
            outline: "none",
            border: "none",
            backgroundColor: "#dfeef7",
          }}
          onChange={handleLengthChange}
        >
          {Object.keys(writingLengths).length === 0 ? (
            <option>Loading...</option> // Default option while waiting for data
          ) : (
            Object.keys(writingLengths).map((key) => (
              <option key={key} value={key}>
                {key}
              </option>
            ))
          )}
        </select>
        {/* <div>
          <p
            style={{
              margin: 0,
              padding: 0,
              marginLeft: "5px",
            }}
            aria-describedby={id}
            onClick={handleClick}
          >
            Medium <IoIosArrowDown />
          </p>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div
              style={{
                padding: "5px",
              }}
            >
              <p>Hello</p>
              <p>Hello</p>
            </div>
          </Popover>
        </div> */}
      </button>
    </div>
  );
};

export default Length;
