import React, { useContext } from "react";
import { TranslationContext } from "../../../..";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import { CalendarDays, FileText, Users, Settings, EllipsisVertical, X } from "lucide-react";
import { IconButton } from "@mui/material";

const MenuBar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const language = useContext(TranslationContext);
  const menuItems = [
    { text: language.MenuBar.Restart, icon: <CalendarDays fontSize="small" /> },
    { text: language.MenuBar.Report, icon: <FileText fontSize="small" /> },
    { text: language.MenuBar.Refresh, icon: <Users fontSize="small" /> },
    { text: language.MenuBar.ManageAddin, icon: <Settings fontSize="small" /> },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (text) => {
    console.log("Clicked Menu Item:", text);
    setAnchorEl(null);
  };

  return (
    <div>
      {/* <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{
          backgroundColor: open ? "rgba(193, 10, 38, 1)" : "#F7F7F7",
          color: open ? "white" : "#2E3A48",
        }}
      >
        {open ? <X /> : <EllipsisVertical />}
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuItems.map((item) => (
          <MenuItem key={item.text} onClick={() => handleClose(item.text)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText>{item.text}</ListItemText>
          </MenuItem>
        ))}
      </Menu> */}
    </div>
  );
};

export default MenuBar;
