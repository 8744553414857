const env = process.env.NODE_ENV || "development";
const isProduction = env !== "development";

export const config = {
  ROBOT_API_URL: "https://rotest.heimat24.de/apps/rosenheim-main/api/",
  ADESSO_API_URL: "https://adessodemo.heimat24.de/apps/adesso-main/api/",
  FINDUS_API_URL: "https://bot108.heimat24.de/apps/bot_findus-main/api/",

  ROBOT_DOMAIN_URL: "https://bot103.heimat24.de/taskpane.html",
  ADESSO_DOMAIN_URL: "https://outlookaddin.heimat24.de/taskpane.html",
  FINDUS_DOMAIN_URL: "https://bot107.heimat24.de/taskpane.html",

  API_BASE_URL: "https://bot107staging.heimat24.de/apps/outlook-backend-base-staging/api/v2/",

};

const getApiUrl = () => {
  const currentDomain = window.location.origin;

  if (currentDomain === new URL(config.ADESSO_DOMAIN_URL).origin) {
    return config.ADESSO_API_URL;
  } else if (currentDomain === new URL(config.ROBOT_DOMAIN_URL).origin) {
    return config.ROBOT_API_URL;
  } else if (currentDomain === new URL(config.FINDUS_DOMAIN_URL).origin) {
    return config.FINDUS_API_URL;
  }

  // Default API if no domain matches
  return config.FINDUS_API_URL;
};

// Export the selected API URL
export const API_URL = getApiUrl();

export default config;
