import React from "react";
import "./Footer.css";
import { useContext } from "react";
import { TranslationContext } from "../../../..";
const Footer = () => {
  const language = useContext(TranslationContext);

  return (
    <div>
      <p className="footer-text">
        <span className="footer-subtext">
          Made by <img src="assets/footerIcon.png" alt="" width={20} /> Development -
        </span>{" "}
        <a href="https://www.h-2-4.de/">{language.welcome.footerSubText}</a>
      </p>
    </div>
  );
};

export default Footer;
