import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { HiOutlineMailOpen } from "react-icons/hi";
import WorkingBtn from "../../Compose/WorkingBtn/WorkingBtn";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "../EmailTranslation/Home/index.css";
import { marked } from "marked";

const EmailSummary = () => {
  const [loader, setLoader] = useState(false);
  const [expanded, setExpanded] = React.useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [historySummary, setHistorySummary] = useState(location.state?.summaryHistory);

  const goBack = () => {
    navigate("/");
  };

  const formattedDate = new Intl.DateTimeFormat('de-DE', {
    timeZone: 'Europe/Berlin',
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  });

  // Handle accordion state and API call for fetching content
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <div>
      {loader && <WorkingBtn />}

      <h3 style={{ padding: "15px" }}>
        <MdOutlineKeyboardBackspace
          fontSize={25}
          onClick={goBack}
          style={{
            margin: "10px",
            cursor: "pointer",
          }}
        />
        E-Mail Zusammenfassung
      </h3>
      <div>
        {historySummary.map(({ sender, date, content }, index) => (
          <Accordion
            sx={{
              border: "2px solid #F2F2F2",
              borderLeft: expanded === index ? "4px solid #000" : "none", // Conditional styling
              boxShadow: "none",
              maxHeight: "50vh",
              overflow: "auto",
            }}
            key={index}
            expanded={expanded === index} // Track if it's open
            onChange={!loader && handleAccordionChange(index)} // Pass index and attachment
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <HiOutlineMailOpen fontSize={24} style={{ paddingRight: "7px" }} />
              {sender} <br />
              {formattedDate.format(new Date(date))}
            </AccordionSummary>
            <AccordionDetails>
              <div
                dangerouslySetInnerHTML={{
                  __html: marked(content),
                }}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default EmailSummary;
