import config from "../../../../config";
export function improveGrammar(content) {
  return new Promise((resolve, reject) => {
    fetch(`${config.API_BASE_URL}improve_grammer`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ content }),
    })
      .then((response) => {
        if (!response.ok) {
          return reject(new Error("Network response was not ok"));
        }
        return response.json();
      })
      .then((data) => resolve(data))
      .catch((error) => reject(error));
  });
}
